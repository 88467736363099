<template>
    <div class="m-0">
        <div class="flex flex-wrap align-items-center justify-content-center p-fluid">
            <div class="col-12 md:col-5">
                <Toast />
                <!-- 預約商品 -->
                <Dialog position="bottom" header="預約商品" v-model:visible="displayReservePopup" closable="false"
                    dismissableMask="true" :breakpoints="{ '960px': '90vw' }" :style="{ width: '30vw' }" :modal="true">
                    <table>
                        <tr>
                            <td class="inline-block w-4"><img class="w-full" :src="reserveImg" /></td>
                            <td class="inline-block w-8 pl-2">
                                <div>{{ reserveName }}</div>
                                <div class="w-full mt-2">
                                    <span class="flex align-items-center justify-content-start"><img
                                            src="/images/p_tag.png" style="width:1.5rem;height:1.5rem" /> &nbsp;{{
                                                reservePoints }}</span>
                                    <span v-if="reserveAddPrice > 0">加購價: {{ reserveAddPrice }}</span>
                                </div>
                                <div class="mt-2 w-full text-pink-600 text-xs">預約期限：{{ reserveAvailableDate }}</div>
                                <div class="mt-2 w-full text-pink-600 text-xs">兌換期限：{{ reserveRedeemDate }}</div>
                            </td>
                        </tr>
                        <tr>
                            <div class="mt-4">請選擇兌換門市</div>
                            <div class="w-full mt-2">
                                <Dropdown emptyMessage="無兌換門市" :class="{ 'p-invalid': reserveInvalidShop }"
                                    class="w-full" v-model="reserveSelectedShop" :options="reserveAvailableShops"
                                    optionLabel="name" placeholder="請選擇兌換門市" />
                            </div>
                            <div class="mt-4">請選擇兌換數量</div>
                            <div class="w-full mt-2">
                                <Dropdown emptyMessage="您的點數不足喔" :class="{ 'p-invalid': reserveInvalidQuantity }"
                                    class="w-full" v-model="reserveSelectedQuantity" :options="reserveAvailableQuantity"
                                    optionLabel="name" placeholder="請選擇兌換數量" />
                            </div>
                            <div class="w-full mt-4">
                                <Button v-on:click="applyToCart" label="預約商品"
                                    class="p-button-secondary p-button w-full" />
                            </div>
                        </tr>
                    </table>
                    <template #footer>
                    </template>
                </Dialog>
                <!-- 提醒有加購金額 -->
                <Dialog header="確認預約" v-model:visible="displayAddPriceConfirmation" :style="{ width: '350px' }"
                    :modal="true">
                    <div class="flex align-items-center justify-content-center">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                        <span>提醒您，本次預約商品有加購金額的部分，請確認無誤再進行預約。</span>
                    </div>
                    <template #footer>
                        <Button label="取消" icon="pi pi-times" @click="closeAddPriceConfirmation"
                            class="p-button-text" />
                        <Button label="下一步" icon="pi pi-check" @click="showReserveFinalConfirmation"
                            class="p-button-text" />
                    </template>
                </Dialog>
                <!-- 確認預約商品 -->
                <Dialog header="確定預約" v-model:visible="displayReserveFinalConfirmation" :style="{ width: '350px' }"
                    :modal="true">
                    <div class="flex align-items-center justify-content-center">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                        <span>提醒您，若要變更商品預約，僅限於該商品「預約期限」內，若於該商品之「兌換期限」間您未領取商品或商品到店後棄單，點數將恕不歸還，謝謝。</span>
                    </div>
                    <template #footer>
                        <Button label="取消" icon="pi pi-times" @click="closeReserveFinalConfirmation"
                            class="p-button-text" />
                        <Button label="確定" icon="pi pi-check" @click="doReserveCart" class="p-button-text" />
                    </template>
                </Dialog>
                <!-- 確認預約商品 -->
                <Dialog header="確定預約" v-model:visible="displayReserveConfirmation" :style="{ width: '350px' }"
                    :modal="true">
                    <div class="flex align-items-center justify-content-center">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                        <span>確定要加入 {{ reserveSelectedQuantity['code'] }} 個 {{ reserveName }} 到預約購物車嗎？</span>
                    </div>
                    <template #footer>
                        <Button label="取消" icon="pi pi-times" @click="closeReserveConfirmation" class="p-button-text" />
                        <Button label="確定" icon="pi pi-check" @click="applyToCart" class="p-button-text" />
                    </template>
                </Dialog>
                <!-- 確認取消商品 -->
                <Dialog header="確定取消" v-model:visible="displayCancelReserveConfirmation" :style="{ width: '350px' }"
                    :modal="true">
                    <div class="flex align-items-center justify-content-center">
                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                        <span>確定要取消此預約項目嗎？</span>
                    </div>
                    <template #footer>
                        <Button label="取消" icon="pi pi-times" @click="closeCancelReserveConfirmation"
                            class="p-button-text" />
                        <Button label="確定" icon="pi pi-check" @click="doCancelReserve" class="p-button-text" />
                    </template>
                </Dialog>
                <!-- 兌換商品 -->
                <Dialog header="掃描兌換商品" :closable="false" :dismissableMask="true" v-model:visible="displayRedeemDialog"
                    :style="{ width: '350px' }" :modal="false">
                    <div class="flex align-items-center justify-content-center">
                        <vue-qrcode :value="redeemQRCode" />
                    </div>
                    <template #footer>
                        <Button label="關閉" icon="pi pi-check" @click="closeRedeemDialog" class="p-button-text" />
                    </template>
                </Dialog>
                <!-- 購物車 -->
                <Dialog position="bottom" header="預約購物車" v-model:visible="displayCartDialog" closable="false"
                    dismissableMask="true" :breakpoints="{ '960px': '96vw' }" :style="{ width: '30vw' }" :modal="true">
                    <div class="grid formgrid col-12">
                        <div v-if="cart.length == 0">目前沒有選擇任何商品喔</div>
                        <div class="col-12 p-2 flex align-items-center justify-content-between "
                            v-for="(item, id) in cart" :key="id">
                            <div class="inline-block col-1">
                                <div class="flex align-items-center justify-content-center bg-gray-100 border-round"
                                    style="width:2.5rem;height:2.5rem">
                                    <i class="pi text-gray-500 text-sm">{{ item.quantity.code }}x</i>
                                </div>
                            </div>
                            <div class="inline-block col-3 text-sm font-bold">{{ item.name }}</div>
                            <div class="inline-block col-2">
                                <div class="flex align-items-center justify-content-start text-sm"
                                    style="height:2.5rem">
                                    <span class="flex align-items-center justify-content-center"><img
                                            src="/images/p_tag.png" style="width:1.5rem;height:1.5rem" />
                                        &nbsp;{{ item.points * item.quantity.code }}
                                    </span>
                                </div>
                            </div>
                            <div class="inline-block col-2 text-sm">
                                &nbsp;${{ item.add_price * item.quantity.code }}
                            </div>
                            <div class="inline-block col-2">
                                <div v-on:click="editCartItem(id)"
                                    class="flex align-items-center justify-content-center bg-blue-100 border-round cursor-pointer"
                                    style="width:2.5rem;height:2.5rem">
                                    <i class="pi pi-pencil text-blue-500 text-sm"></i>
                                </div>
                            </div>
                            <div class="inline-block col-2">
                                <div v-on:click="removeCartItem(id)"
                                    class="flex align-items-center justify-content-center bg-blue-100 border-round cursor-pointer"
                                    style="width:2.5rem;height:2.5rem">
                                    <i class="pi pi-trash text-blue-500 text-sm"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 總和計算 -->
                    <div class="flex align-items-center justify-content-end font-bold">
                        <span>總計點數:&nbsp;</span>
                        <div class="flex align-items-center justify-content-start text-sm" style="height:2.5rem">
                            <span class="flex align-items-center justify-content-center"><img src="/images/p_tag.png"
                                    style="width:1.5rem;height:1.5rem" />
                                &nbsp; {{ computedPoints }}
                            </span>
                        </div>
                    </div>
                    <div class="flex align-items-center justify-content-end font-bold">
                        <span>&nbsp;總計加購價:&nbsp;</span>
                        <div class="inline-block text-sm">
                            &nbsp; ${{ computedAddPrice }}
                        </div>
                    </div>
                    <template #footer>
                        <!-- 確認 -->
                        <div class="w-full mb-2">
                            <Button v-if="this.cart.length != 0" v-on:click="submitCart" label="確認預約"
                                class="p-button-secondary p-button w-full" />
                        </div>
                    </template>
                </Dialog>
                <!-- new popup dialog to display an image for size -->
                <Dialog position="bottom" header="尺寸表" v-model:visible="displaySizePopup" closable="true"
                    dismissableMask="true" :breakpoints="{ '960px': '75vw' }" :style="{ width: '45vw' }" :modal="true">
                    <div class="flex align justify-content-center">
                        <img class="w-full" src="/images/ads/sizes.jpg" />
                    </div>
                    <template #footer>
                    </template>
                </Dialog>


                <!-- 顯示錯誤 -->
                <Dialog position="bottom" header="預約結果" v-model:visible="displayReserveSummaryPopup" closable="false"
                    dismissableMask="true" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
                    <p class="line-height-3 m-0">
                    <ul id="example-1" class="p-1 m-0">
                        <div v-for="summary in reserveSummary" :key="summary" class="mt-2">
                            - {{ summary }}
                        </div>
                    </ul>
                    </p>
                    <template #footer>
                    </template>
                </Dialog>

                <!-- 會員點數補登 -->
                <Dialog header="會員點數登錄" :closable="false" :dismissableMask="true" v-model:visible="displayClaimPoints"
                    :style="{ width: '300px' }" :modal="false">
                    <div class="flex align-items-center justify-content-center">
                        <div class="grid formgrid">
                            <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                                <span class="p-input-icon-left p-float-label">
                                    <i class="pi pi-file" />
                                    <InputText type="text" id="invoice-num" v-model="claimInvoiceValue"
                                        v-bind:class="{ 'p-invalid': isInvalidInvoiceNum }" />
                                    <label for="invoice-num">發票號碼 (英文+數字)</label>
                                </span>
                            </div>
                            <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                                <span class="p-input-icon-left p-float-label">
                                    <i class="pi pi-calendar" />
                                    <InputText type="text" id="invoice-date" v-model="claimDateValue"
                                        v-bind:class="{ 'p-invalid': isInvalidInvoiceDate }" />
                                    <label for="invoice-date">消費日期 (例: 20230516)</label>
                                </span>
                            </div>
                            <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                                <span class="p-input-icon-left p-float-label">
                                    <i class="pi pi-dollar" />
                                    <InputText type="text" id="invoice-price" v-model="claimPriceValue"
                                        v-bind:class="{ 'p-invalid': isInvalidInvoicePrice }" />
                                    <label for="invoice-price">發票總金額</label>
                                </span>
                            </div>
                            <!--<div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                                <div class="field-checkbox mb-0">
                                    <Checkbox id="checkOption1" name="option" value="agree" v-model="checkboxValue" />
                                    <label for="checkOption1">我確實擁有此發票。</label>
                                </div>
                            </div>-->
                            <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                                <Button v-on:click="doClaimPoints()" label="登錄此發票"
                                    class="p-button-secondary p-button mr-2" />
                            </div>
                            <div class="col-12 mt-4 lg:col-12 lg:mb-0">
                                * 請確認您確實擁有此發票，謝謝
                            </div>
                            <div class="col-12 mb-2 lg:col-12 lg:mb-0">
                                * 發票補登需於 1 個月內進行補登
                            </div>
                        </div>
                    </div>
                    <template #footer>
                        <Button label="關閉" icon="pi pi-check" @click="closeClaimPointsDialog" class="p-button-text" />
                    </template>
                </Dialog>

                <div v-if="(statusLogin == 0 || statusLogin == 200) && currentPage == 'home'" class="card mt-4">
                    <div style="text-align: center">
                        <span class="w-full flex">
                            <span class="flex-grow-1 w-5 p-buttonset inline-block mr-1">
                                <Button label="可使用點數" icon="pi pi-star" iconPos="top"
                                    class="text-xs h-4rem w-8 p-button-secondary" @click="togglePointsDetail" />
                                <Button :label="this.totalPoints + '點'" class="text-xs h-4rem w-4 p-button-secondary"
                                    @click="togglePointsDetail" />
                            </span>
                            <OverlayPanel ref="pointsDetail" class="flex flex-column">
                                <div class="flex justify-content-between"><span>我的點數：</span><span>{{ this.totalPoints }}
                                        點</span></div>
                                <div class="flex justify-content-between"><span>即將到期：</span><span>{{ this.expiringPoints
                                        }} 點</span></div>
                                <div class="flex justify-content-between"><span>到期時間：</span><span>{{
                                    this.expiringDate.replace(/-/g, '/')
                                        }}</span>
                                </div>
                            </OverlayPanel>
                            <span class="flex-grow-1 w-5 p-buttonset inline-block ml-1">
                                <Button label="可兌換商品" icon="pi pi-box" iconPos="top"
                                    class="text-xs h-4rem w-8 p-button-purpply" />
                                <Button :label="this.totalReserved + '件'" class="text-xs h-4rem w-4 p-button-purpply" />
                            </span>
                        </span>
                        <!--<iframe class="mt-4" ref="bdIframe" onload="this.style.height=(this.contentWindow.document.body.scrollHeight+20)+'px';" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>-->
                    </div>
                    <div v-if="this.userPoints && 'last_transaction' in this.userPoints" class="p-0 w-full">
                        <div style="text-align: right" class="text-gray-500 mt-2 text-xs"
                            v-if="'time' in this.userPoints.last_transaction">上次消費:
                            {{ this.userPoints.last_transaction.time }} 累積 {{ this.userPoints.last_transaction.point }}
                            點
                        </div>
                        <div v-if="this.showExpiringHint" style="text-align: right"
                            class="text-gray-800 mt-1 -mb-2 text-xs">
                            * 您有 <span class="text-red-700">{{ expiringPoints }}</span>
                            點將於 <span class="text-red-700">{{ expiringDate.replace(/-/g, '/') }}</span> 到期
                        </div>
                    </div>
                </div>
                <!--<div v-if="(statusLogin == 0 || statusLogin == 200) && currentPage == 'home'" class="card mt4">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-500 font-medium mb-3">公告</span>
                            <div class="text-900 font-medium">
                                親愛的會員您好<br />
                                近期點數計算程式出現異常<br />
                                會有出現負數的狀況。<br />

                                我們目前正在修正程式更新中<br />
                                會儘快於本週解決<br />
                                於下週恢復正常<br />
                                造成不便，還請見諒<br />
                                謝謝😊<br />
                            </div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                            <i class="pi pi-comment text-blue-500 text-xl"></i>
                        </div>
                    </div>
                </div>-->
                <div v-if="(statusLogin == 0 || statusLogin == 200) && currentPage == 'home'" class="card mt4">
                    <div class="flex justify-content-between align-items-center mb-3">
                        <span class="text-500 font-medium">公告</span>
                        <span class="text-blue-500">2024-02-19</span>
                    </div>
                    <p class="text-900 font-medium" :style="{ width: '240px' }">
                        親愛的會員您好，<br />
                        自 2024-04-01 起，點數累計之計算方法將進行調整。由原先消費金額達 30 元可累計 1 點，調整為消費金額達 35 元可累計 1 點。2024-04-01
                        前，一樣為消費滿 30 元可累計 1 點。此調整措施不會影響您於 2024-03-31 前累積之消費金額點數計算方法，謝謝您。<br /><br />
                        另外，提醒您，2024-01-01 前累計的點數僅提供您使用至 2024-03-31，還請儘早規畫商品兌換唷，謝謝。
                    </p>
                </div>
                <div v-if="(statusLogin == 0 || statusLogin == 200) && currentPage == 'home'" class="card mt4">
                    <div class="flex justify-content-between align-items-center mb-3">
                        <span class="text-500 font-medium">公告</span>
                        <span class="text-blue-500">2023-07-25</span>
                    </div>
                    <p class="text-900 font-medium" :style="{ width: '240px' }">
                        親愛的會員您好，<br />
                        自 2023-07-25 起，點數累計及兌換商品之使用方法將有所調整。自 2024 年起，將以當年度之消費進行點數累積，當年度所累積之會員點數，提供各位會員使用至隔年
                        3 月 31 日，若點數到期時未使用完畢，系統將自動將點數歸零。<br /><br />
                        提醒您，此調整措施不會影響您目前累績之點數，但因有此新調整，您現有的點數及本年度所累積之點數，僅提供您使用至 2024-03-31，請儘早規畫商品兌換唷，謝謝。
                    </p>
                </div>
                <div v-if="(statusLogin == 0 || statusLogin == 200) && currentPage == 'home'" class="card mt4">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-500 font-medium mb-3">公告</span>
                            <div class="text-900 font-medium">
                                <div class="mb-2">
                                    <img src="images/ads/index-h2.png" />
                                </div>
                                茶即是生活態度。<br />
                                我們相信每一杯茶代表的都是一段情感、一個回憶。<br />
                                喝茶自然而然地成為我們生活的一部分。<br />
                                我們自許不只追求泡茶手藝，更能將茶的生活態度推廣至全世界。<br />
                            </div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
                            style="width:2.5rem;height:2.5rem">
                            <i class="pi pi-comment text-blue-500 text-xl"></i>
                        </div>
                    </div>
                </div>
                <div v-if="(statusLogin == 0 || statusLogin == 200) && currentPage == 'points'" class="card mt-4 p-0">
                    <div style="text-align: center">
                        <span class="w-full p-buttonset">
                            <Button label="預約商品"
                                v-bind:class="{ 'p-button-purpply': currentPointsPage == 'available', 'bg-gray-800': currentPointsPage != 'available', 'border-gray-800': currentPointsPage != 'available' }"
                                v-on:click="setCurrentPointsPage('available')" icon="pi pi-calendar" iconPos="top" />
                            <Button label="兌換商品"
                                v-bind:class="{ 'p-button-purpply': currentPointsPage == 'reserve', 'bg-gray-800': currentPointsPage != 'reserve', 'border-gray-800': currentPointsPage != 'reserve' }"
                                v-on:click="setCurrentPointsPage('reserve')" icon="pi pi-box" iconPos="top" />
                            <Button label="已換商品"
                                v-bind:class="{ 'p-button-purpply': currentPointsPage == 'redeem', 'bg-gray-800': currentPointsPage != 'redeem', 'border-gray-800': currentPointsPage != 'redeem' }"
                                v-on:click="setCurrentPointsPage('redeem')" icon="pi pi-shopping-bag" iconPos="top" />
                        </span>
                    </div>
                </div>
                <div v-if="(statusLogin == 0 || statusLogin == 200) && currentPage == 'points' && currentPointsPage == 'available'"
                    class="w-full">
                    <div v-if="this.userPoints['available_list'].length > 0" class="flex flex-wrap">
                        <div v-for="(item, index) in this.userPoints['available_list']" v-bind:class="{
                            'mr-2': index % 2 == 0 && index != this.userPoints['available_list'].length - 1,
                            'flex-grow-1': index < this.userPoints['available_list'].length - 1 || this.userPoints['available_list'].length % 2 == 0,
                            'w-6': this.userPoints['available_list'].length % 2 == 1 && index == this.userPoints['available_list'].length - 1,
                        }" v-bind:key="index"
                            class="w-5 card inline-block align-items-center justify-content-center p-3">
                            <!-- 品項內容 -->
                            <div class="w-full"><img :src="this.userPoints['image_map'][item.gift_id]" class="w-full" />
                            </div>
                            <div class="w-full font-bold mt-2 text-center">{{ item.name }}</div>
                            <div class="w-full text-center cursor-pointer" v-if="item.name.includes('十週年')"
                                v-on:click="(displaySizePopup = true)">請點此查看尺寸表</div>
                            <div class="w-full text-center">
                                <div class="px-2 flex align-items-center justify-content-center border-round w-full font-semibold"
                                    style="height:2.5rem">
                                    <span class="flex align-items-center justify-content-center"><img
                                            src="/images/p_tag.png" style="width:1.5rem;height:1.5rem" />
                                        &nbsp;{{ item.points }}</span>
                                    <span v-if="parseInt(item.add_price) > 0">&nbsp;加購價: ${{ item.add_price }}</span>
                                </div>
                                <Button v-on:click="showReserveDialog(item)" label="立即預約" icon="pi pi-calendar"
                                    class="p-button-purpply p-button-outlined" />
                                <div class="mt-2 w-full text-pink-600 text-xs">
                                    預約期限<br />
                                    {{ item['available_date'] }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="flex flex-wrap">
                        <div class="card w-full text-center">
                            <div class="my-2"><img src="images/none.png" /></div>
                            <div class="my-2">目前無可預約的商品</div>
                            <div class="my-2">請隨時關注耐心等候喔</div>
                        </div>
                    </div>

                    <div class="col-12 flex justify-content-end flex-wrap">
                        <div class="fixed bottom-0">
                            <!-- footer bar -->
                            <div class="mb-8 relative">
                                <Button v-on:click="showCart()" label="購物車" icon="pi pi-shopping-cart" iconPos="top"
                                    class="p-button-purpply" />
                                <span v-if="cart.length > 0"
                                    class="absolute right-0 bottom-0 mb-4 mr-2 text-pink-300">●</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="(statusLogin == 0 || statusLogin == 200) && currentPage == 'points' && currentPointsPage == 'reserve'"
                    class="w-full">
                    <div v-if="this.userPoints['reserve_list'].length > 0" class="flex flex-wrap">
                        <div v-for="(item, index) in this.userPoints['reserve_list']" v-bind:class="{
                            'mr-2': index % 2 == 0 && index != this.userPoints['reserve_list'].length - 1,
                            'flex-grow-1': index < this.userPoints['reserve_list'].length - 1 || this.userPoints['reserve_list'].length % 2 == 0,
                            'w-6': this.userPoints['reserve_list'].length % 2 == 1 && index == this.userPoints['reserve_list'].length - 1,
                        }" v-bind:key="index"
                            class="w-5 card inline-block align-items-center justify-content-center p-3">
                            <!-- 品項內容 -->
                            <div class="w-full"><img :src="this.userPoints['image_map'][item.gift_id]" class="w-full" />
                            </div>
                            <div class="w-full font-bold mt-2 text-center">{{ item.name }} x{{ item.quantity }}</div>
                            <div class="w-full text-center">
                                <div class="px-2 flex align-items-center justify-content-center border-round w-full font-semibold"
                                    style="height:2.5rem">
                                    <span class="flex align-items-center justify-content-center"><img
                                            src="/images/p_tag.png" style="width:1.5rem;height:1.5rem" />
                                        &nbsp;{{ item.points * item.quantity }}</span>
                                    <span v-if="parseInt(item.add_price) > 0">&nbsp;加購價:
                                        ${{ item.add_price * item.quantity }}</span>
                                </div>
                                <Button v-if="item.can_redeem" v-on:click="showRedeemDialog(item)" label="立即兌換"
                                    icon="pi pi-ticket" class="p-button-purpply p-button-outlined mt-2" />
                                <Button
                                    v-if="item.can_cancel && this.userPoints['available_list'].findIndex((x) => x.gift_id === item.gift_id) !== -1"
                                    v-on:click="confirmCancelReserve(item)" label="取消預約" icon="pi pi-times"
                                    class="p-button-purpply p-button-outlined mt-2" />
                                <div class="mt-2 w-full text-pink-600 text-xs">
                                    兌換期限<br />
                                    {{ item['redeem_date'] }}
                                </div>
                                <div class="mt-2 w-full text-xs">
                                    兌換門市: {{ this.userPoints['branch_map'][item.redeem_shop.toString()] }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="flex flex-wrap">
                        <div class="card w-full text-center">
                            <div class="my-2"><img src="images/none.png" /></div>
                            <div class="my-2">目前無可兌換的商品</div>
                            <div class="my-2">請先點選左上方 "預約商品" 開始預約喔</div>
                        </div>
                    </div>
                </div>
                <div v-if="(statusLogin == 0 || statusLogin == 200) && currentPage == 'points' && currentPointsPage == 'redeem'"
                    class="w-full">
                    <div v-if="this.userPoints['redeem_list'].length > 0" class="flex flex-wrap">
                        <div v-for="(item, index) in this.userPoints['redeem_list']" v-bind:class="{
                            'mr-2': index % 2 == 0 && index != this.userPoints['redeem_list'].length - 1,
                            'flex-grow-1': index < this.userPoints['redeem_list'].length - 1 || this.userPoints['redeem_list'].length % 2 == 0,
                            'w-6': this.userPoints['redeem_list'].length % 2 == 1 && index == this.userPoints['redeem_list'].length - 1,
                        }" v-bind:key="index" class="w-5 card inline-block align-items-center justify-content-center">

                            <!-- 品項內容 -->
                            <div class="w-full font-bold mt-2 text-center">{{ item.name }} x{{ item.quantity }}</div>
                            <div class="w-full text-center">
                                <div class="px-2 flex align-items-center justify-content-center border-round w-full font-semibold"
                                    style="height:2.5rem">
                                    <span class="flex align-items-center justify-content-center"><img
                                            src="/images/p_tag.png" style="width:1.5rem;height:1.5rem" />
                                        &nbsp;{{ item.points * item.quantity }}</span>
                                    <span v-if="parseInt(item.add_price) > 0">&nbsp;加購價:
                                        ${{ item.add_price * item.quantity }}</span>
                                </div>
                                <div class="mt-2 w-full text-xs">
                                    兌換: {{ this.userPoints['branch_map'][item.redeem_shop.toString()] }}
                                    ({{ item.redeem_time }})
                                </div>
                            </div>

                        </div>
                    </div>
                    <div v-else class="flex flex-wrap">
                        <div class="card w-full text-center">
                            <div class="my-2"><img src="images/none.png" /></div>
                            <div class="my-2">目前尚未兌換過商品</div>
                            <div class="my-2">請先點選左上方 "預約商品" 開始預約喔</div>
                        </div>
                    </div>
                </div>
                <div v-if="(statusLogin == 0 || statusLogin == 200) && currentPage == 'preference'" class="w-full mt-4">
                    <div class="flex flex-wrap cursor-pointer">
                        <div class="card w-full text-center my-1" v-on:click="showClaimPointsDialog()">
                            <i class="pi pi-credit-card text-blue-100 text-xl"></i> &nbsp; 發票點數登錄
                        </div>
                        <div class="card w-full text-center my-1" v-on:click="doLogout()">
                            <i class="pi pi-user text-blue-100 text-xl"></i> &nbsp; 登出系統
                        </div>
                    </div>
                </div>
                <div v-if="statusLogin >= 400" class="card">
                    <div class="mb-4 mt-4">
                        <InlineMessage>[{{ this.statusLogin }}] 登入資訊有誤，請您重新登入，謝謝</InlineMessage>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full fixed top-0 left-0">
            <!-- header bar -->
            <div
                class="w-full flex align-items-center justify-content-between flex-wrap card-container green-container bg-white purpply-bottombar p-0">
                <span>&nbsp;</span>
                <span>
                    <img src="images/logo-anh.png" class="h-4rem p-2" />
                </span>
                <span class="flex align-items-center justify-content-center mr-4" @click="togglePointsDetail">
                    <img src="/images/p_tag.png" style="width:1.5rem;height:1.5rem" />
                    <span class="mx-1">{{ totalPoints }}</span>
                    <!-- <span class="text-gray-700">({{ expiringPoints }} 將到期)</span> -->
                </span>
            </div>
        </div>
        <div class="w-full fixed bottom-0 left-0">
            <!-- footer bar -->
            <div
                class="w-full flex align-items-center justify-content-center flex-wrap card-container green-container bg-gray-800 purpply-bottombar p-0">
                <span class="p-buttonset">
                    <Button v-on:click="setCurrentPage('home')" label="首頁" icon="pi pi-home" iconPos="top"
                        class="bg-gray-800 border-gray-800 p-button-purpply" />
                    <Button v-on:click="setCurrentPage('points')" label="點數兌換" icon="pi pi-calendar-plus" iconPos="top"
                        class="bg-gray-800 border-gray-800 p-button-purpply" />
                    <Button v-on:click="showPortalDialog()" label="網路點餐" icon="pi pi-shopping-bag" iconPos="top"
                        class="bg-gray-800 border-gray-800 p-button-purpply" />
                    <Button v-on:click="setCurrentPage('preference')" label="會員功能" icon="pi pi-user" iconPos="top"
                        class="bg-gray-800 border-gray-800 p-button-purpply" />
                </span>
            </div>
        </div>
        <Dialog v-model:visible="displayPortalDialog" :modal="true" :style="{ width: '350px' }" :closable="true"
            :dismissableMask="true" :draggable="false" :resizable="false" :contentStyle="{
                overflow: 'visible', borderBottomLeftRadius: '6px',
                borderBottomRightRadius: '6px',
            }">
            <template #header>
                <span class="mx-auto p-dialog-title">請選擇您欲使用的服務</span>
            </template>
            <div class="flex flex-column gap-4">
                <Button v-on:click="onClickMenuSite('onsite')" label="網路來店點餐 (直接點餐)"
                    class="p-button-purpply p-button" />
                <Button v-on:click="onClickMenuSite('pickup')" label="網路自取點餐 (預約自取)"
                    class="p-button-purpply p-button" />
                <Button v-on:click="onClickMenuSite('delivery')" label="網路外送點餐 (預約外送)"
                    class="p-button-purpply p-button" />
            </div>
        </Dialog>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            statusLogin: 0,
            currentPage: 'home',
            currentPointsPage: 'available',
            totalReserved: 0,
            totalPoints: 0,

            expiringPoints: 0,
            expiringDate: '',
            showExpiringHint: false,

            reserveImg: '',
            reserveName: '',
            reserveGiftID: 0,
            reservePoints: 0,
            reserveAddPrice: 0,
            reserveAvailableDate: '',
            reserveSelectedShop: '',
            reserveSelectedQuantity: { 'code': 0 },
            reserveAvailableShops: [],
            reserveAvailableQuantity: [],
            reserveRedeemDate: '',
            reserveInvalidShop: false,
            reserveInvalidQuantity: false,

            cart: [],

            redeemQRCode: "test",

            cancelReserveRedeemID: 0,

            displayReservePopup: false,
            displayReserveSummaryPopup: false,
            displayReserveConfirmation: false,
            displayReserveFinalConfirmation: false,
            displayAddPriceConfirmation: false,
            displayRedeemPopup: false,
            displayCancelReserveConfirmation: false,
            displayRedeemDialog: false,
            displayCartDialog: false,
            displaySizePopup: false,

            displayClaimPoints: false,
            claimInvoiceValue: '',
            claimDateValue: '',
            claimPriceValue: '',

            displayPortalDialog: false,
        };
    },
    mounted() {
        this.doLogin();
    },
    beforeUnmount() { },
    created() { },
    methods: {
        doLogout() {
            localStorage.onlineOrderToken = "";
            // store brand name first
            localStorage.brand = "";
            localStorage.brandName = "";
            localStorage.token = "";
            localStorage.userPoints = "";

            this.$router.push({
                path: "", // Entrance
                name: "login",
            });
        },
        doLogin() {
            this.refreshUserPoints();

            /*this.$axios
                .get("/brandLogin", {
                    params: {
                        token: token,
                    },
                })
                .then((response) => {
                    console.log(response.data);
                    if (response.data["code"] == 200 && response.data["intent"] == "redirect")
                        this.doRedirect(response.data);
                    else this.statusLogin = response.data["code"];
                });*/
        },
        refreshUserPoints() {
            axios.defaults.withCredentials = true;
            axios(process.env.VUE_APP_API_BASE_URL + "/userPoints", {
                method: "GET",
                withCredentials: true,
                params: {
                    token: localStorage.token,
                },
            })
                .then((response) => {
                    console.log(response.data);
                    if (response.data["code"] == 200) {
                        this.refreshStoredData(response.data);
                    }
                    if (response.data["code"] == 200 && response.data["intent"] == "redirect")
                        this.doRedirect(response.data);
                    else this.statusLogin = response.data["code"];
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        doRedirect(data) {
            var page = data["page"];
            var hash = data["hash"];
            var b = data["b"];

            this.$router.replace({
                path: "/" + page, // Entrance
                name: page,
                query: {
                    hash: hash,
                    b: b,
                },
            });
        },
        loadIframe() {
            const oIframe = this.$refs.ooframe;
            console.log(oIframe);
        },
        refreshHomeIframe() {
            // 重新更改 iframe 大小
            const oIframe = this.$refs.bdIframe;
            oIframe.style.webkitTransform = 'scale(1)';
            const deviceWidth = document.documentElement.clientWidth;
            const deviceHeight = document.documentElement.clientHeight;
            oIframe.style.width = (Number(deviceWidth) - 120) + 'px'; //數字是頁面佈局高度差
            oIframe.style.height = (Number(deviceHeight) - 250) + 'px'; //數字是頁面佈局高度差
            if ((Number(deviceWidth) - 120) > 500) oIframe.style.width = 500 + 'px'; // 最大 500 px (from facebook)
            //oIframe.src = "https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fanicehoilday%2F&width=" + oIframe.style.width + "&height=" + oIframe.style.height + "&tabs=timeline&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId&timestamp=" + time.toString();
            oIframe.src = "https://www.aniceholiday.com.tw/";
        },
        setCurrentPage(page) {
            this.currentPage = page;
        },
        setCurrentPointsPage(page) {
            this.currentPointsPage = page;
        },
        showPortalDialog() {
            this.displayPortalDialog = true;
        },
        onClickMenuSite(type) {
            window.open(`${process.env.VUE_APP_API_BASE_URL}/menuSite?token=${localStorage.token}&redirect=${type}`, '_blank');
            this.displayPortalDialog = false;
        },
        refreshStoredData(data) {
            // just for testing
            // data['available_list'] = [];
            // data['reserve_list'] = [];
            // data['redeem_list'] = [];

            localStorage.userPoints = data;
            this.userPoints = data;

            // 計算可兌換數量
            var totalReserved = 0;
            for (var i = 0; i < data["reserve_list"].length; i++)
                if (data["reserve_list"][i]["can_redeem"])
                    totalReserved += parseInt(data["reserve_list"][i]["quantity"]);
            this.totalReserved = totalReserved;

            // 剩餘點數
            this.totalPoints = data["remaining"];

            // 即將到期點數
            this.expiringPoints = data["expiring"];
            this.expiringDate = data["expiring_date"];

            // 顯示即將到期點數提示
            const timeDiff = new Date(data['expiring_date']).getTime() - Date.now();
            const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
            if (this.expiringPoints > 0 && daysDiff <= 100) {
                this.showExpiringHint = true;
            }
        },
        showReserveDialog(item) {
            console.log(item);
            this.reserveImg = this.userPoints['image_map'][item.gift_id];
            this.reserveName = item.name,
                this.reserveGiftID = item.gift_id,
                this.reservePoints = item.points,
                this.reserveAddPrice = item.add_price,
                this.reserveAvailableDate = item.available_date,
                this.reserveRedeemDate = item.redeem_date,
                this.reserveSelectedShop = '';
            this.reserveSelectedQuantity = { 'code': 0 };
            this.flagInitDialog = 'defaultItem';
            this.editingItemIndex = -1;

            this.refreshReserveDialogOptions(item);

            this.displayReservePopup = true;
        },
        refreshReserveDialogOptions(item) {
            this.reserveAvailableShops = [];
            this.reserveAvailableQuantity = [];

            // 門市清單
            for (var i = 0; i < this.userPoints['shop_map'][item.gift_id].length; i++)
                this.reserveAvailableShops.push(
                    {
                        name: this.userPoints['branch_map'][this.userPoints['shop_map'][item.gift_id][i].toString()],
                        code: this.userPoints['shop_map'][item.gift_id][i],
                    }
                );

            // 先計算購物車
            var cart_total_points = 0;
            for (i = 0; i < this.cart.length; i++) {
                if (this.editingItemIndex == i) continue;
                cart_total_points += this.cart[i].points * this.cart[i].quantity.code;
            }

            console.log("cart_total_points: " + cart_total_points);
            console.log("editingItemIndex: " + this.editingItemIndex);
            console.log("remaining_points: " + this.userPoints['remaining'] - cart_total_points);
            console.log("this.userPoints['remaining']: " + this.userPoints['remaining']);

            // 計算剩餘點數
            var remaining_points = this.userPoints['remaining'] - cart_total_points;
            var max_quantity = 20;
            if (item.points > 0) max_quantity = Math.floor(remaining_points / item.points);

            console.log("max_quantity: " + max_quantity);

            for (i = 1; i <= max_quantity; i++)
                this.reserveAvailableQuantity.push(
                    {
                        name: i,
                        code: i,
                    }
                );
        },
        showRedeemDialog(item) {
            console.log(item);
            axios.defaults.withCredentials = true;
            var bodyFormData = new FormData();
            bodyFormData.append('a', 'redeem');
            bodyFormData.append('g', item.redeem_id);
            bodyFormData.append('s', '');
            bodyFormData.append('token', localStorage.token);
            axios(process.env.VUE_APP_API_BASE_URL + "/operG", {
                method: "POST",
                withCredentials: true,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                console.log(response.data);
                if (response.data["code"] == 200) {
                    this.redeemQRCode = response.data["msg"];
                    this.displayRedeemDialog = true;
                } else if (response.data["code"] == 400) {
                    this.$toast.add({ severity: 'error', summary: '取消失敗', detail: response.data['msg'], life: 3000 });
                }
            })
                .catch((err) => {
                    this.$toast.add({ severity: 'error', summary: '取消失敗', detail: '請檢查網路狀態後再嘗試一次', life: 3000 });
                    console.log(err.response);
                });
        },
        closeRedeemDialog() {
            this.refreshUserPoints();
            this.displayRedeemDialog = false;
        },
        confirmCancelReserve(item) {
            this.cancelReserveRedeemID = item.redeem_id;
            this.displayCancelReserveConfirmation = true;
        },
        doCancelReserve() {
            axios.defaults.withCredentials = true;
            var bodyFormData = new FormData();
            bodyFormData.append('a', 'cancel');
            bodyFormData.append('g', this.cancelReserveRedeemID);
            bodyFormData.append('s', '');
            bodyFormData.append('token', localStorage.token);
            axios(process.env.VUE_APP_API_BASE_URL + "/operG", {
                method: "POST",
                withCredentials: true,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                console.log(response.data);
                if (response.data["code"] == 200) {
                    this.$toast.add({ severity: 'success', summary: '預約取消', detail: '預約之商品已取消預約，謝謝', life: 3000 });
                    this.closeCancelReserveConfirmation();
                    this.displayReservePopup = false;

                    this.refreshUserPoints();
                } else if (response.data["code"] == 400) {
                    this.$toast.add({ severity: 'error', summary: '取消失敗', detail: response.data['msg'], life: 3000 });
                }
            })
                .catch((err) => {
                    this.$toast.add({ severity: 'error', summary: '取消失敗', detail: '請檢查網路狀態後再嘗試一次', life: 3000 });
                    console.log(err.response);
                });
        },
        closeCancelReserveConfirmation() {
            this.displayCancelReserveConfirmation = false;
        },
        confirmReserve() {
            this.reserveInvalidShop = false;
            this.reserveInvalidQuantity = false;

            if (this.reserveSelectedShop == '') {
                this.reserveInvalidShop = true;
                return;
            }
            if (this.reserveSelectedQuantity.code == 0) {
                this.reserveInvalidQuantity = true;
                return;
            }
            this.displayReserveConfirmation = true;
        },
        closeReserveConfirmation() {
            this.displayReserveConfirmation = false;
        },
        doReserve() {
            axios.defaults.withCredentials = true;
            var bodyFormData = new FormData();
            bodyFormData.append('a', 'reserve');
            bodyFormData.append('s', this.reserveSelectedShop['code']);
            bodyFormData.append('g', this.reserveGiftID);
            bodyFormData.append('q', this.reserveSelectedQuantity['code']);
            bodyFormData.append('token', localStorage.token);
            axios(process.env.VUE_APP_API_BASE_URL + "/operG", {
                method: "POST",
                withCredentials: true,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                console.log(response.data);
                if (response.data["code"] == 200) {
                    this.$toast.add({ severity: 'success', summary: '預約成功', detail: '預約之商品已加入"兌換商品"清單，謝謝', life: 3000 });
                    this.closeReserveConfirmation();
                    this.displayReservePopup = false;

                    this.refreshUserPoints();
                } else {
                    this.$toast.add({ severity: 'error', summary: '預約失敗', detail: response.data["msg"], life: 3000 });
                }
            })
                .catch((err) => {
                    this.$toast.add({ severity: 'error', summary: '預約失敗', detail: '請檢查網路狀態後再嘗試一次', life: 3000 });
                    console.log(err.response);
                });
        },
        showAddPriceConfirmation() {
            this.displayAddPriceConfirmation = true;
        },
        closeAddPriceConfirmation() {
            this.displayAddPriceConfirmation = false;
        },
        showReserveFinalConfirmation() {
            this.displayReserveFinalConfirmation = true;
        },
        closeReserveFinalConfirmation() {
            this.displayAddPriceConfirmation = false;
            this.displayReserveFinalConfirmation = false;
        },
        doReserveCart() {
            this.closeReserveFinalConfirmation();

            var shop_list = [];
            var gift_list = [];
            var quantity_list = [];

            // build lists from cart
            for (var i = 0; i < this.cart.length; i++) {
                if (this.cart[i]['quantity']['code'] == 0) {
                    this.$toast.add({ severity: 'error', summary: '預約失敗', detail: '預約數量不得為零', life: 3000 });
                    return;
                }
                shop_list.push(this.cart[i]['shop_id']['code']);
                gift_list.push(this.cart[i]['gift_id']);
                quantity_list.push(this.cart[i]['quantity']['code']);
            }

            console.log(shop_list);
            console.log(gift_list);
            console.log(quantity_list);

            axios.defaults.withCredentials = true;
            var bodyFormData = new FormData();
            bodyFormData.append('a', 'reserve_list');
            bodyFormData.append('s', JSON.stringify(shop_list));
            bodyFormData.append('g', JSON.stringify(gift_list));
            bodyFormData.append('q', JSON.stringify(quantity_list));
            bodyFormData.append('token', localStorage.token);
            axios(process.env.VUE_APP_API_BASE_URL + "/operG", {
                method: "POST",
                withCredentials: true,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                console.log(response.data);
                if (response.data["code"] == 200) {
                    this.$toast.add({ severity: 'success', summary: '預約成功', detail: '預約之商品已加入"兌換商品"清單，謝謝', life: 3000 });
                    this.refreshUserPoints();
                    this.cart = [];
                    this.displayCartDialog = false;
                } else {
                    this.$toast.add({ severity: 'error', summary: '預約失敗', detail: "請確認預約結果，謝謝", life: 3000 });
                    this.reserveSummary = [];
                    for (i = 0; i < response.data["msg"].length; i++) {
                        this.reserveSummary.push(this.cart[i].name + ": " + response.data["msg"][i]["msg"]);
                    }
                    this.refreshUserPoints();
                    this.displayCartDialog = false;
                    this.displayReserveSummaryPopup = true;
                }
            })
                .catch((err) => {
                    this.$toast.add({ severity: 'error', summary: '預約失敗', detail: '請檢查網路狀態後再嘗試一次', life: 3000 });
                    console.log(err.response);
                });
        },
        showCart() {
            this.displayCartDialog = true;
        },
        applyToCart() {
            // build item
            var item = {}
            item['name'] = this.reserveName;
            item['gift_id'] = this.reserveGiftID;
            item['image'] = this.reserveImg;
            item['shop_id'] = this.reserveSelectedShop;
            item['quantity'] = this.reserveSelectedQuantity;
            item['points'] = this.reservePoints;
            item['add_price'] = this.reserveAddPrice;
            item['available_date'] = this.reserveAvailableDate;
            item['redeem_date'] = this.reserveRedeemDate;

            console.log(this.reserveSelectedShop);
            if (this.reserveSelectedShop == "") {
                this.$toast.add({ severity: 'error', summary: '加入失敗', detail: '請先選擇預約門市', life: 3000 });
                return;
            }
            if (this.reserveSelectedQuantity.code == 0) {
                this.$toast.add({ severity: 'error', summary: '加入失敗', detail: '請先選擇預約數量', life: 3000 });
                return;
            }

            if (this.flagInitDialog == 'loadItem') {
                // add to cart by cloning first
                this.cart.splice(this.editingItemIndex, 1, JSON.parse(JSON.stringify(item)));
                this.$toast.add({ severity: 'success', summary: '已選商品紀錄', detail: '品項已更新至已選商品紀錄', life: 3000 });
            } else {
                // add to cart by cloning first
                this.cart.push(JSON.parse(JSON.stringify(item)));
                this.$toast.add({ severity: 'success', summary: '已選商品紀錄', detail: '品項已加入已選商品紀錄', life: 3000 });
            }
            this.closeReserveConfirmation();
            this.displayReservePopup = false;
        },
        editCartItem(id) {
            var item = JSON.parse(JSON.stringify(this.cart[id]));

            // reverse item
            this.reserveName = item.name;
            this.reserveGiftID = item.gift_id;
            this.reserveImg = item.image;
            this.reserveSelectedShop = item['shop_id'];
            this.reserveSelectedQuantity = item['quantity'];
            this.reservePoints = item['points'];
            this.reserveAddPrice = item['add_price'];
            this.reserveAvailableDate = item['available_date'];
            this.reserveRedeemDate = item['redeem_date'];

            this.editingItemIndex = id;

            this.refreshReserveDialogOptions(item);

            this.flagInitDialog = 'loadItem'
            this.displayReservePopup = true;
        },
        removeCartItem(id) {
            this.cart.splice(id, 1);
            this.$toast.add({ severity: 'success', summary: '已選商品紀錄', detail: '品項已從已選商品紀錄移除', life: 3000 });
        },
        submitCart() {
            if (this.computedAddPrice > 0)
                this.showAddPriceConfirmation();
            else
                this.showReserveFinalConfirmation();
        },
        showClaimPointsDialog() {
            this.displayClaimPoints = true;
        },
        closeClaimPointsDialog() {
            this.displayClaimPoints = false;
        },
        doClaimPoints() {
            // check if the invoice format is two alphabets and 8 digits
            if (!this.claimInvoiceValue.match(/^[A-Z]{2}[0-9]{8}$/)) {
                this.$toast.add({ severity: 'error', summary: '發票號碼格式錯誤', detail: '請確認發票號碼格式', life: 3000 });
                return;
            }
            // check if the date format is YYYYMMDD
            if (!this.claimDateValue.match(/^[0-9]{8}$/)) {
                this.$toast.add({ severity: 'error', summary: '發票日期格式錯誤', detail: '請確認發票日期格式', life: 3000 });
                return;
            }
            // check if the amount is a number
            if (isNaN(this.claimPriceValue)) {
                this.$toast.add({ severity: 'error', summary: '發票金額格式錯誤', detail: '請確認發票金額格式', life: 3000 });
                return;
            }
            // check if the amount is a positive number
            if (this.claimPriceValue <= 0) {
                this.$toast.add({ severity: 'error', summary: '發票金額格式錯誤', detail: '請確認發票金額格式', life: 3000 });
                return;
            }

            // call rest api
            var bodyFormData = new FormData();
            bodyFormData.append('token', localStorage.token);
            bodyFormData.append('invoice_num', this.claimInvoiceValue);
            bodyFormData.append('price', this.claimPriceValue);
            bodyFormData.append('date', this.claimDateValue);

            axios(process.env.VUE_APP_API_BASE_URL + "/bindInvoice", {
                method: "POST",
                withCredentials: true,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            }).then((response) => {
                console.log(response.data);
                if (response.data["code"] == 200) {
                    this.$toast.add({ severity: 'success', summary: '領取成功', detail: '點數將於明日更新', life: 3000 });
                } else if (response.data["code"] == 403) {
                    // {"code": 403, "message": "invoice not found"}
                    this.$toast.add({ severity: 'error', summary: '領取失敗', detail: "請確認發票資訊是否正確", life: 3000 });
                } else if (response.data["code"] == 404) {
                    // {"code": 404, "message": "invoice already bound"}
                    this.$toast.add({ severity: 'error', summary: '領取失敗', detail: "此發票已成功由您領取過點數", life: 3000 });
                } else if (response.data["code"] == 405) {
                    // {"code": 405, "message": "the invoice is already binded to another account"}
                    this.$toast.add({ severity: 'error', summary: '領取失敗', detail: "此發票已被其他會員領取過點數", life: 3000 });
                } else {
                    this.$toast.add({ severity: 'error', summary: '領取失敗', detail: "請確認領取結果，謝謝", life: 3000 });
                }
                this.refreshUserPoints();
                this.displayClaimPoints = false;
            })
                .catch((err) => {
                    this.$toast.add({ severity: 'error', summary: '領取失敗', detail: '請檢查網路狀態後再嘗試一次', life: 3000 });
                    console.log(err.response);
                });
        },
        togglePointsDetail(event) {
            this.$refs.pointsDetail.toggle(event)
        },
    },
    computed: {
        computedPoints() {
            var points = 0;
            for (var i = 0; i < this.cart.length; i++) {
                points += parseInt(this.cart[i]['points']) * parseInt(this.cart[i]['quantity']['code']);
            }
            return points;
        },
        computedAddPrice() {
            var add_price = 0;
            for (var i = 0; i < this.cart.length; i++) {
                add_price += parseInt(this.cart[i]['add_price']) * parseInt(this.cart[i]['quantity']['code']);
            }
            return add_price;
        },
    },
};
</script>

<style scoped lang="scss">
.btn {
    border-radius: 10px;
    border-bottom: 0px;
    display: inline-block;
    padding: 10px;
    position: relative;
    text-align: center;
    transition: background 600ms ease, color 600ms ease;
}

input[type="radio"].toggle {
    display: none;

    &+label {
        cursor: pointer;
        min-width: 120px;
        background-color: var(--purpply-unselected-color);

        &:hover {
            background: none;
            color: var(--purpply-primary-color);
        }

        &:after {
            background-color: var(--purpply-primary-color);
            content: "";
            height: 100%;
            position: absolute;
            top: 0;
            transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
            width: 100%;
            z-index: -1;
        }
    }

    &.toggle-left+label {
        border-right: 0;

        &:after {
            left: 100%;
        }
    }

    &.toggle-right+label {
        margin-left: 0px;

        &:after {
            left: -100%;
        }
    }

    &:checked+label {
        cursor: default;
        color: #fff;
        background-color: var(--purpply-primary-color);
        transition: color 200ms;

        &:after {
            left: 0;
        }
    }
}
</style>
